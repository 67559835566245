import type { NavigationGuard } from 'vue-router';
import { state } from '@/store';

export const requireAuth: NavigationGuard = (to, __from, next) => {
  if (!state.auth.isLoggedIn) {
    next({
      name: 'Authentication',
      query: {
        ...to.query,
        redirect: to.fullPath,
      },
    });
  } else if (state.auth.user?.consent === null) {
    state.misc.showConsentModal = true;

    next();
  } else {
    next();
  }
};

export const requireUnauth: NavigationGuard = (__to, __from, next) => {
  if (state.auth.isLoggedIn) {
    next({ name: 'Calculate' });
  } else {
    next();
  }
};
