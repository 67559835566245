import { TemperatureUnit } from '@/types/enums';
import { round } from '.';

export const convertTemperature = ({
  from,
  to,
  amount,
}: {
  from: TemperatureUnit | string;
  to: TemperatureUnit | string;
  amount: number;
}): number => {
  let convertedToKelvin = 0;
  let newTemperature = amount;

  switch (from) {
    case TemperatureUnit.CELSIUS: {
      convertedToKelvin = amount + 273.15;
      break;
    }
    case TemperatureUnit.FAHRENHEIT: {
      convertedToKelvin = (amount - 32) * (5 / 9) + 273.15;
      break;
    }
    default:
    case TemperatureUnit.KELVIN: {
      convertedToKelvin = amount;
      break;
    }
  }

  switch (to) {
    case TemperatureUnit.CELSIUS: {
      newTemperature = convertedToKelvin - 273.15;
      break;
    }
    case TemperatureUnit.FAHRENHEIT: {
      newTemperature = (convertedToKelvin - 273.15) * (9 / 5) + 32;
      break;
    }
    default:
    case TemperatureUnit.KELVIN: {
      newTemperature = convertedToKelvin;
      break;
    }
  }

  // Round to 2 decimals
  newTemperature = round(newTemperature);

  return newTemperature;
};
