import { VUE_APP_API_ENDPOINT } from '@/constants';
import Cookies from 'js-cookie';

interface RequestParams {
  body?: BodyInit;
  urlParams?: Record<string, string>;
  headers?: Record<string, string>;
}

interface RequestResponse<T> {
  ok: true;
  status: number;
  data: T;
}

interface RequestResponseError {
  ok: false;
  status: number;
  data: {
    error: string;
    statusCode: number;
    message: string[];
  };
}

export class BaseService {
  static readonly API_ENDPOINT = VUE_APP_API_ENDPOINT;
  static readonly COOKIE_ACCESS_TOKEN = 'access-token';
  static readonly COOKIE_REFRESH_TOKEN = 'refresh-token';

  static get accessToken() {
    return Cookies.get(BaseService.COOKIE_ACCESS_TOKEN);
  }
  static set accessToken(to: string | undefined) {
    if (to === undefined) {
      Cookies.remove(BaseService.COOKIE_ACCESS_TOKEN);
    } else {
      Cookies.set(BaseService.COOKIE_ACCESS_TOKEN, to);
    }
  }

  static get refreshToken() {
    return Cookies.get(BaseService.COOKIE_REFRESH_TOKEN);
  }
  static set refreshToken(to: string | undefined) {
    if (to === undefined) {
      Cookies.remove(BaseService.COOKIE_REFRESH_TOKEN);
    } else {
      Cookies.set(BaseService.COOKIE_REFRESH_TOKEN, to);
    }
  }

  getHeaders(): HeadersInit {
    return {
      ...(BaseService.accessToken && {
        Authorization: `Bearer ${BaseService.accessToken}`,
      }),
      'Content-Type': 'application/json',
    };
  }

  protected get<T>(url: string, requestParams?: RequestParams) {
    return this.request<T>('GET', url, requestParams);
  }
  protected put<T>(url: string, requestParams?: RequestParams) {
    return this.request<T>('PUT', url, requestParams);
  }
  protected post<T>(url: string, requestParams?: RequestParams) {
    return this.request<T>('POST', url, requestParams);
  }
  protected patch<T>(url: string, requestParams?: RequestParams) {
    return this.request<T>('PATCH', url, requestParams);
  }
  protected delete<T>(url: string, requestParams?: RequestParams) {
    return this.request<T>('DELETE', url, requestParams);
  }

  private async request<T>(
    method: string,
    url: string,
    { body, urlParams, headers }: RequestParams = {},
    { attempt }: { attempt?: number } = { attempt: 0 },
  ): Promise<RequestResponse<T> | RequestResponseError> {
    let location = `${BaseService.API_ENDPOINT}/${url}`;

    if (urlParams && Object.keys(urlParams)) {
      location += `?${new URLSearchParams(urlParams)}`;
    }

    const response = await fetch(location, {
      method,
      headers: {
        ...this.getHeaders(),
        ...headers,
      },
      body,
    });

    let data;

    const sentHeaders = {
      ...this.getHeaders(),
      ...headers,
    } as Record<string, string>;

    if (sentHeaders['Content-Type'] === 'application/json') {
      // Some responses such as 201 might not have any content to parse, therefor we try it, and if it fails, we return an empty object
      try {
        data = await response.json();
      } catch {
        data = {};
      }
    } else if (
      sentHeaders['Content-Type'] ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      try {
        data = await response.blob();
      } catch {
        data = {};
      }
    }

    if (!response.ok) {
      if (attempt ?? 0 >= 3) {
        BaseService.accessToken = undefined;
        BaseService.refreshToken = undefined;
      }

      // Transform single message to array anyway
      if (typeof data.message === 'string') {
        data.message = [data.message];
      }

      if (response.status === 401 && BaseService.refreshToken) {
        BaseService.accessToken = BaseService.refreshToken;

        const authRefresh = await fetch(
          `${BaseService.API_ENDPOINT}/auth/refresh`,
          {
            method: 'GET',
            headers: {
              ...this.getHeaders(),
              ...headers,
            },
          },
        );

        if (authRefresh.ok) {
          const data = await authRefresh.json();

          BaseService.accessToken = data.accessToken;

          return this.request<T>(
            method,
            url,
            { body, urlParams, headers },
            { attempt: attempt ?? 0 + 1 },
          );
        }
      }

      if (response.status >= 500) {
        console.error(response);
      }

      return {
        ok: false,
        status: response.status,
        data,
      } as RequestResponseError;
    }

    return {
      ok: true,
      status: response.status,
      data,
    } as RequestResponse<T>;
  }
}
