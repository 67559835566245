import { ID_SEPARATOR } from '@/constants';
import { BaseIngredient } from '@/types/ingredients';

export * from './uuid';
export * from './select-options';
export * from './temperature';

export const delay = (duration: number) =>
  new Promise((resolve) => setTimeout(resolve, duration));

export const round = (number: number, decimals = 2) =>
  Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;

export const logInfo = () => {
  const date = new Date(process.env.APPLICATION_TIME_OF_BUILD);

  console.group(
    '%cApplication information',
    'font-family: monospace; font-size: 14px; font-weight: bold;',
  );
  console.table({
    Name: process.env.APPLICATION_NAME,
    Version: process.env.APPLICATION_VERSION,
    Environment: process.env.APPLICATION_ENVIRONMENT,
    'Build date': date.toLocaleDateString(),
    'Build time': date.toLocaleTimeString(),
    'Build ISO DateTime': date.toISOString(),
  });
  console.groupEnd();
};

export const zeroPad = (num: number, places: number) => {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
};

export function getUniqueIdIngredient(
  ingredient?: Partial<BaseIngredient> | string,
) {
  if (typeof ingredient === 'string') return ingredient;

  if (!ingredient || !ingredient.ingredientId || !ingredient.ingredientKind)
    return;

  return [
    ingredient.ingredientId,
    ID_SEPARATOR,
    ingredient.ingredientKind,
  ].join('');
}

export function isSameIngredient(
  ingredientA: Partial<BaseIngredient> | string,
  ingredientB: Partial<BaseIngredient> | string,
) {
  return (
    getUniqueIdIngredient(ingredientA) === getUniqueIdIngredient(ingredientB)
  );
}

export function countDecimals(number: number) {
  if (Math.floor(number.valueOf()) === number.valueOf()) return 0;

  const str = number.toString();
  if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    return Number(str.split('-')[1]) || 0;
  } else if (str.indexOf('.') !== -1) {
    return str.split('.')[1].length || 0;
  }
  return Number(str.split('-')[1]) || 0;
}
