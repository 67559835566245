import { DEFAULT_SETTINGS, STORAGE_PREFIX } from '@/constants';
import { SscUserSettingDto } from '@/types/user-setting';
import { ref, reactive, watch } from 'vue';

const LS_NAME = `${STORAGE_PREFIX}USER`;

const settings = ref<SscUserSettingDto>(DEFAULT_SETTINGS);

export const user = reactive({
  settings,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  user.settings = data.settings;
};

init();

watch(user, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
