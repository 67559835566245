import { CreateEmailDto } from '@/types/email';
import { BaseService } from './base-service';

class MiscService extends BaseService {
  async sendEmail(email: CreateEmailDto) {
    return await this.post('send-email', { body: JSON.stringify(email) });
  }
}

export const miscService = new MiscService();
