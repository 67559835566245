import {
  AmountUnit,
  TemperatureUnit,
  IngredientCategory,
  PressureUnit,
  Salutation,
  CompanyType,
  JobPosition,
  CompanyEmployeeCount,
} from '@/types/enums';

export const amountUnits = [
  { label: 'wt.% (total weight)', value: AmountUnit.WEIGHT_PERCENTAGE },
  { label: 'g/kg water', value: AmountUnit.GRAM_PER_KILOGRAM_OF_WATER },
];

export const temperatureUnits = [
  { label: '°C', value: TemperatureUnit.CELSIUS },
  { label: '°F', value: TemperatureUnit.FAHRENHEIT },
  { label: 'K', value: TemperatureUnit.KELVIN },
];

export const pressureUnits = [
  { label: 'bar', value: PressureUnit.BAR },
  { label: 'mBar', value: PressureUnit.MILLIBAR },
  { label: 'atm', value: PressureUnit.ATMOSPHERE },
  { label: 'Pa', value: PressureUnit.PASCAL },
  { label: 'mmHg', value: PressureUnit.MILLIMETER_OF_MERCURY },
];

export const salutations = [
  { label: 'Prefer not to say', value: Salutation.NOT_SET },
  { label: 'Mr.', value: Salutation.MR },
  { label: 'Mrs.', value: Salutation.MRS },
  { label: 'Ms.', value: Salutation.MS },
];

export const companyTypes = [
  { label: 'Supplier', value: CompanyType.SUPPLIER },
  { label: 'Manufacturer', value: CompanyType.MANUFACTURER },
];

export const jobPositions = [
  {
    label: 'Manufacturing or production',
    value: JobPosition.MANUFACTURING_OR_PRODUCTION,
  },
  { label: 'Marketing and sales', value: JobPosition.MARKETING_AND_SALES },
  { label: 'NPF or development', value: JobPosition.NPF_OR_DEVELOPMENT },
  { label: 'Purchasing', value: JobPosition.PURCHASING },
  { label: 'Quality assurance', value: JobPosition.QUALITY_ASSURANCE },
  { label: 'Quality control', value: JobPosition.QUALITY_CONTROL },
  {
    label: 'Research and development',
    value: JobPosition.RESEARCH_AND_DEVELOPMENT,
  },
  { label: 'Other', value: JobPosition.OTHER },
];

export const companyEmployeeCounts = [
  { label: 'Prefer not to say', value: CompanyEmployeeCount.NOT_SET },
  { label: 'From 0 to 10', value: CompanyEmployeeCount.FROM_0_TO_10 },
  { label: 'From 10 to 100', value: CompanyEmployeeCount.FROM_10_TO_100 },
  { label: 'From 100 to 250', value: CompanyEmployeeCount.FROM_100_TO_250 },
  { label: 'From 250 to 1000', value: CompanyEmployeeCount.FROM_250_TO_1000 },
  { label: 'More than 1000', value: CompanyEmployeeCount.MORE_THAN_1000 },
];

export const countries = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AO', label: 'Angola' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia-Herzegovina' },
  { value: 'BR', label: 'Brazil' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JP', label: 'Japan' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MK', label: 'Macedonia' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MX', label: 'Mexico' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar/Burma' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PA', label: 'Panama' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia Montenegro' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovak Republic' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'KR', label: 'South Korea' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'US', label: 'USA' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const categoriesList = [
  { label: 'Strong acids', value: IngredientCategory.STRONG_ACID },
  { label: 'Weak acids', value: IngredientCategory.WEAK_ACID },
  { label: 'Strong bases', value: IngredientCategory.STRONG_BASE },
  { label: 'Weak bases', value: IngredientCategory.WEAK_BASE },
  { label: 'Non electrolytes', value: IngredientCategory.NONELECTROLYTE },
  { label: 'Salts', value: IngredientCategory.SALT },
  { label: 'Gases', value: IngredientCategory.GAS },
  { label: 'Amphoteres', value: IngredientCategory.AMPHOTER },
  { label: 'Predefined solutions', value: IngredientCategory.NULL },
];
