<template>
  <div class="sb_icon-loader">
    <sb-icon name="loader" />
  </div>
</template>

<style scoped lang="scss">
.sb_icon-loader {
  --loader-size: 24px;

  width: var(--loader-size);
  height: var(--loader-size);

  > * {
    --icon-size: var(--loader-size);

    will-change: transform;
    animation-name: spin;
    animation-duration: $transition-duration * 2.5;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    // spinning animation
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
