import {
  CreateUserDto,
  UserDto,
  CreateUserEventDto,
  RequestPasswordResetDto,
  ResetPasswordDto,
} from '@/types/user';
import {
  CreateSscUserSettingDto,
  SscUserSettingDto,
} from '@/types/user-setting';
import { BaseService } from './base-service';

class UserService extends BaseService {
  async getUser() {
    return await this.get<UserDto>('user');
  }

  async acceptConsent() {
    return await this.patch('user/consent');
  }

  async getSettings() {
    return await this.get<SscUserSettingDto>('user/setting');
  }

  async putSettings(settings: CreateSscUserSettingDto) {
    return await this.put('user/setting', { body: JSON.stringify(settings) });
  }

  async postEvent(event: CreateUserEventDto) {
    return await this.post('user/event', { body: JSON.stringify(event) });
  }

  async registerUser(user: CreateUserDto) {
    return await this.post<UserDto>('user/register', {
      body: JSON.stringify(user),
    });
  }

  async requestPasswordReset(body: RequestPasswordResetDto) {
    return await this.put('user/request-password-reset', {
      body: JSON.stringify(body),
    });
  }

  async resetPassword(body: ResetPasswordDto) {
    return await this.put('user/reset-password', {
      body: JSON.stringify(body),
    });
  }
}

export const userService = new UserService();
