import { authService } from '@/services/auth-service';
import { BaseService } from '@/services/base-service';
import { userService } from '@/services/user-service';
import { state } from '@/store';
import { CredentialsDto } from '@/types/auth';
import {
  CreateUserDto,
  CreateUserEventDto,
  RequestPasswordResetDto,
  ResetPasswordDto,
} from '@/types/user';
import { checkAndPossiblyStartOnboarding } from '@/store/onboarding/actions';
import { getSettings, resetSettings } from '@/store/user/actions';
import { auth } from './state';

export const login = async (credentials: CredentialsDto) => {
  const response = await authService.login(credentials);

  switch (response.status) {
    case 200:
    case 201: {
      if (response.ok) {
        await getUser();
      }

      return { success: true };
    }
    case 401: {
      return { success: false, error: 'Invalid credentials' };
    }
    case 404: {
      return { success: false, error: 'User not found' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('login: Unhandled response', response);
      } else {
        console.error('login: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const refresh = async () => {
  const response = await authService.refresh();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        await getUser();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('refresh: Unhandled response', response);
      } else {
        console.error('refresh: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const logout = async () => {
  const response = await authService.logout();

  switch (response.status) {
    case 200:
    case 204: {
      auth.user = undefined;

      resetSettings();
      localStorage.clear();
      BaseService.accessToken = undefined;
      BaseService.refreshToken = undefined;

      return { success: true };
    }
    case 401: {
      auth.user = undefined;

      resetSettings();
      localStorage.clear();
      BaseService.accessToken = undefined;
      BaseService.refreshToken = undefined;

      return { success: true, error: 'Unauthorized' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('logout: Unhandled response', response);
      } else {
        console.error('logout: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const postEvent = async (event: CreateUserEventDto) => {
  const response = await userService.postEvent(event);

  switch (response.status) {
    case 200:
    case 204: {
      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('postEvent: Unhandled response', response);
      } else {
        console.error('postEvent: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getUser = async () => {
  const response = await userService.getUser();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        auth.user = response.data;

        if (auth.user.consent === null) {
          state.misc.showConsentModal = true;
        }

        await getSettings();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getUser: Unhandled response', response);
      } else {
        console.error('getUser: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const acceptConsent = async () => {
  const response = await userService.acceptConsent();

  switch (response.status) {
    case 204: {
      if (response.ok) {
        await getUser();

        checkAndPossiblyStartOnboarding();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('acceptConsent: Unhandled response', response);
      } else {
        console.error('acceptConsent: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const registerUser = async (user: CreateUserDto) => {
  const response = await userService.registerUser(user);

  switch (response.status) {
    case 200:
    case 201: {
      await login({ email: user.email, password: user.password });

      return { success: true };
    }
    case 400: {
      if (!response.ok) {
        let error = '';
        response.data.message.forEach((message) => {
          const duplicateEntryRegex =
            /^Duplicate entry '([^']*)' for key '([^']*)'$/i;
          const duplicateEntryTest = duplicateEntryRegex.exec(message);

          if (duplicateEntryTest?.length === 3) {
            switch (duplicateEntryTest[2]) {
              case 'email': {
                error = 'Email already registered';
              }
            }
          }
        });

        return {
          success: false,
          error,
          message: response.data.message,
        };
      }

      return { success: false, error: 'Something went wrong...' };
    }
    case 403: {
      if (!response.ok) {
        return { success: false, error: response.data.message[0] };
      }

      return { succes: false, error: 'Forbidden' };
    }
    case 409: {
      return { success: false, error: 'Email already registered' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('registerUser: Unhandled response', response);
      } else {
        console.error('registerUser: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const requestPasswordReset = async (body: RequestPasswordResetDto) => {
  const response = await userService.requestPasswordReset(body);

  switch (response.status) {
    case 200:
    case 204: {
      return { success: true };
    }
    case 404: {
      return { success: false, error: 'User not found' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('requestPasswordReset: Unhandled response', response);
      } else {
        console.error('requestPasswordReset: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const resetPassword = async (body: ResetPasswordDto) => {
  const response = await userService.resetPassword(body);

  switch (response.status) {
    case 200:
    case 204: {
      await login({ email: body.email, password: body.password });

      return { success: true };
    }
    case 404: {
      return { success: false, error: 'User not found' };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('resetPassword: Unhandled response', response);
      } else {
        console.error('resetPassword: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};
