import { TokensDto, CredentialsDto, AccessTokenDto } from '@/types/auth';
import { BaseService } from './base-service';

class AuthService extends BaseService {
  async login(credentials: CredentialsDto) {
    const response = await this.post<TokensDto>('auth/login', {
      body: JSON.stringify(credentials),
    });

    if (response.ok) {
      switch (response.status) {
        case 200:
        case 201: {
          BaseService.accessToken = response.data.accessToken;
          BaseService.refreshToken = response.data.refreshToken;
        }
      }
    }

    return response;
  }

  async refresh() {
    BaseService.accessToken = BaseService.refreshToken;

    const response = await this.get<AccessTokenDto>('auth/refresh');

    if (response.ok) {
      switch (response.status) {
        case 200: {
          BaseService.accessToken = response.data.accessToken;
        }
      }
    }

    return response;
  }

  async logout() {
    const response = await this.get('auth/logout');

    if (response.ok) {
      switch (response.status) {
        case 200: {
          BaseService.accessToken = undefined;
          BaseService.refreshToken = undefined;
        }
      }
    }

    return response;
  }
}

export const authService = new AuthService();
