import { createApp } from 'vue';
import { default as FloatingVue } from 'floating-vue';
import { default as Toast } from 'vue-toastification';
import { router } from '@/router';
import { tooltipOptions, toastOptions } from '@/options';
import App from './App.vue';
import { logInfo } from '@/utils';
import { registerComponents } from './registerComponents';

import './registerServiceWorker';
import '@/styles/app.scss';

const app = createApp(App);

// vue-router
app.use(router);

// floating-vue
app.use(FloatingVue, tooltipOptions);

// vue-toastification
app.use(Toast, toastOptions);

// Register components
registerComponents(app);

app.mount('#app');

logInfo();
