import { state } from '@/store';
import { onboarding, tour } from './state';

export const checkAndPossiblyStartOnboarding = () => {
  if (!onboarding.completed && state.auth.user?.consent !== null) {
    tour.addStep(
      {
        id: 'step0',
        title: 'Welcome!',
        text: 'It looks like you are a first time user, do you want a general tutorial?',
        buttons: [
          {
            text: '<button class="sb_button sb_button--outline sb_button_skip">Skip</button>',
            action: tour.cancel,
          },
          {
            text: '<button class="sb_button sb_button--primary"><span>Start</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
            action: tour.next,
          },
        ],
      },
      0,
    );
    tour.removeStep('step1');
    tour.addStep(
      {
        id: 'step1',
        title: 'Inputs',
        text: 'In this section you can select ingredients, add extra ingredients, add amount of ingredient in formulation and include extra scenarios for comparison.',
        attachTo: {
          element: '.sb_calculate_inputs_inner',
          on: 'auto',
        },
        buttons: [
          {
            text: '<button class="sb_button sb_button--outline sb_button_previous">Previous</button>',
            action: tour.back,
          },
          {
            text: '<button class="sb_button sb_button--primary"><span>Next</span><span class="sb_icon"><svg role="img"><use xlink:href="#icon_chevron-right-squared" /></svg></span></button>',
            action: tour.next,
          },
        ],
      },
      1,
    );

    startOnboarding();
  }
};

export const startOnboarding = () => {
  if (onboarding.active) {
    return;
  }

  tour.start();
};
