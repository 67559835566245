<template>
  <svg>
    <!-- Global UI icons -->
    <symbol id="icon_add-squared" viewBox="0 0 500 500">
      <path
        d="M420.5,225.21H274.79V79.5H226.21V225.21H80.5v48.58H226.21V419.5h48.58V273.79H420.5Z" />
    </symbol>

    <symbol id="icon_arrow-down-squared" viewBox="0 0 500 500">
      <path
        d="M115.84,214.16,80,250,250,420,420,250l-35.84-35.84L275.35,323V80h-50.7V323Z" />
    </symbol>

    <symbol id="icon_arrow-right-squared" viewBox="0 0 500 500">
      <path
        d="M214.16,384.16,250,420,420,250,250,80l-35.84,35.84L323,224.65H80v50.7H323Z" />
    </symbol>

    <symbol id="icon_bin" viewBox="0 0 500 500">
      <path
        d="M148,165H131V386a34,34,0,0,0,34,34H335a34,34,0,0,0,34-34V165Zm180.51-51L301,80H199l-27.51,34H97v34H403V114Z" />
    </symbol>

    <symbol id="icon_calculator" viewBox="0 0 500 500">
      <path
        d="M369,80H131a34,34,0,0,0-34,34V386a34,34,0,0,0,34,34H369a34,34,0,0,0,34-34V114A34,34,0,0,0,369,80ZM131,386V114H369l0,272Zm34-136h34v34H165Zm0,68h34v34H165Zm68-68h34v34H233ZM165,148H335v68H165Zm68,170h34v34H233Zm68-68h34V352H301Z" />
    </symbol>

    <symbol id="icon_chevron-down-squared" viewBox="0 0 500 500">
      <path
        d="M377.88,172.83,250,292.4,122.12,172.83,80,212.21l170,159,170-159Z" />
    </symbol>

    <symbol id="icon_chevron-left-squared" viewBox="0 0 500 500">
      <path
        d="M349.19,377.88,229.62,250,349.19,122.12,309.81,80l-159,170,159,170Z" />
    </symbol>

    <symbol id="icon_chevron-right-squared" viewBox="0 0 500 500">
      <path
        d="M150.83,122.12,270.4,250,150.83,377.88,190.21,420l159-170-159-170Z" />
    </symbol>

    <symbol id="icon_chevron-up-squared" viewBox="0 0 500 500">
      <path
        d="M80,313.94l42.12,42.12L250,228.18,377.88,356.06,420,313.94l-170-170Z" />
    </symbol>

    <symbol id="icon_disclaimer" viewBox="0 0 500 500">
      <path
        d="M250,420c93.74,0,170-76.26,170-170S343.74,80,250,80,80,156.26,80,250,156.26,420,250,420ZM233,165h34V267H233Zm0,136h34v34H233Z" />
    </symbol>

    <symbol id="icon_doc" viewBox="0 0 500 500">
      <path
        d="M352,420a34,34,0,0,0,34-34V182L284,80H148a34,34,0,0,0-34,34V386a34,34,0,0,0,34,34ZM267,114l85,85H267ZM165,182h51v34H165Zm0,68H335v34H165Zm0,68H335v34H165Z" />
    </symbol>

    <symbol id="icon_done" viewBox="0 0 500 500">
      <path
        d="M191,314.45l-77.68-77.67L80,270.13l111,111,229-229-33.35-33.35Z" />
    </symbol>

    <symbol id="icon_dropdown" viewBox="0 0 500 500">
      <path
        d="M236,341.56c3.16,3.5,8.4,5.58,14,5.58s10.82-2.08,14-5.58L417,173.18a10.4,10.4,0,0,0,1.07-13.38c-2.93-4.26-8.72-6.94-15-6.94H97c-6.33,0-12.13,2.68-15.07,6.94s-2.51,9.42,1.09,13.38Z" />
    </symbol>

    <symbol id="icon_edit" viewBox="0 0 500 500">
      <path
        d="M153.17,345.92l80.29-.27a.18.18,0,0,0,.12,0L409.31,171.11A36.43,36.43,0,0,0,420,144.7a37.66,37.66,0,0,0-11.24-25.88l-28.4-28.47c-13.79-13.83-37.86-13.76-51.55,0L153.05,264.82a.15.15,0,0,0-.05.12v80.81A.17.17,0,0,0,153.17,345.92ZM354.68,116.34l28.75,28.72a.18.18,0,0,1,0,.25L354.53,174a.18.18,0,0,1-.24,0l-28.7-28.76a.16.16,0,0,1,0-.24l28.85-28.67A.18.18,0,0,1,354.68,116.34ZM189.55,280.08,299.42,171a.18.18,0,0,1,.24,0l28.7,28.78a.16.16,0,0,1,0,.24L218.51,309.06a.21.21,0,0,1-.12.05l-28.72.09a.18.18,0,0,1-.18-.17V280.2A.16.16,0,0,1,189.55,280.08ZM80,383.41V127.27a36.58,36.58,0,0,1,36.5-36.59H258.15a8.22,8.22,0,0,1,5.82,14l-20.11,20.16a8.2,8.2,0,0,1-5.82,2.41H124.71a8.22,8.22,0,0,0-8.21,8.22v239.7a8.22,8.22,0,0,0,8.21,8.22h46.15c.62,0,1.23.18,1.83.18s1-.18,1.44-.18H363.77a8.22,8.22,0,0,0,8.22-8.22V264.81a8.21,8.21,0,0,1,2.4-5.8l20.06-20.12a8.21,8.21,0,0,1,14,5.8V383.41A36.57,36.57,0,0,1,372,420H116.5A36.58,36.58,0,0,1,80,383.41Z" />
    </symbol>

    <symbol id="icon_elevator" viewBox="0 0 500 500">
      <path
        d="M153.94,283.4a9.22,9.22,0,0,0,.62,9.76L242,415.93a9.87,9.87,0,0,0,16,0l87.39-122.77a9.25,9.25,0,0,0,.62-9.76,9.72,9.72,0,0,0-8.6-5.06H162.55A9.74,9.74,0,0,0,153.94,283.4Zm192.12-66.8a9.2,9.2,0,0,0-.62-9.75L258,84.07a9.87,9.87,0,0,0-16,0L154.68,206.85a9.21,9.21,0,0,0-.62,9.75,9.72,9.72,0,0,0,8.6,5.06H337.45A9.74,9.74,0,0,0,346.06,216.6Z" />
    </symbol>

    <symbol id="icon_faq" viewBox="0 0 500 500">
      <path
        d="M250,150.83c-31.25,0-56.67,25.82-56.67,57.56H216c0-19,15.25-34.54,34-34.54s34,15.5,34,34.54c0,12.3-5.15,16.86-16.78,26a140.63,140.63,0,0,0-13.9,11.95c-14.87,15.09-14.71,30-14.65,31.16v23h22.66V277.34c0-.27.26-6.91,8-14.78a118.68,118.68,0,0,1,11.68-10c12-9.4,25.64-20,25.64-44.2C306.67,176.65,281.25,150.83,250,150.83ZM238.67,312h22.66v23H238.67ZM250,420c-93.74,0-170-76.26-170-170S156.26,80,250,80s170,76.26,170,170S343.74,420,250,420Zm0-315.21c-80.07,0-145.21,65.14-145.21,145.21S169.93,395.21,250,395.21,395.21,330.07,395.21,250,330.07,104.79,250,104.79Z" />
    </symbol>

    <symbol id="icon_globe" viewBox="0 0 500 500">
      <path
        d="M250,80C156.26,80,80,156.26,80,250s76.26,170,170,170,170-76.26,170-170S343.74,80,250,80ZM384.83,233h-47a249.53,249.53,0,0,0-30.46-106.13A136.25,136.25,0,0,1,384.83,233ZM259,114.46c17.6,23.19,41.26,64.26,44.66,118.54H199.51c2.36-44.13,16.9-85.48,41.67-118.56,2.92-.17,5.85-.44,8.82-.44S256,114.27,259,114.46Zm-65.31,11.9C177,158.51,167.31,195,165.51,233H115.17A136.18,136.18,0,0,1,193.7,126.36ZM115.17,267h50.56C168,307.44,177,343.13,192.18,372.91a136.18,136.18,0,0,1-77-105.91ZM240.65,385.54c-23.82-28.87-37.88-69.31-41-118.54H303.62c-3.54,47.14-19,88.33-44.25,118.52-3.1.21-6.19.48-9.37.48S243.76,385.73,240.65,385.54Zm68.19-13.12C325.07,341.92,335,306.1,337.58,267h47.23a136.1,136.1,0,0,1-76,105.42Z" />
    </symbol>

    <symbol id="icon_info" viewBox="0 0 500 500">
      <path
        d="M467,250c0,119.85-97.15,217-217,217S33,369.85,33,250,130.15,33,250,33A217,217,0,0,1,467,250Z M250,182.35c16.62,0,30.38-13.37,30.38-30s-13.76-30-30.38-30a30.53,30.53,0,0,0-21.42,8.76,29.63,29.63,0,0,0-9,21.21C219.62,169,233.38,182.35,250,182.35Z M250,224.25c-16.62,0-30.38,13.44-30.38,28.82V361.65c0,15.39,13.76,27.94,30.38,27.94s30.38-12.56,30.38-27.94V253.07C280.38,237.67,266.62,224.25,250,224.25Z" />
    </symbol>

    <symbol id="icon_link" viewBox="0 0 500 500">
      <path
        d="M179.57,235.92c22.57-22.58,61.94-22.58,84.51,0a19.92,19.92,0,0,0,28.17-28.17,99.55,99.55,0,0,0-140.85,0L109.13,250A99.61,99.61,0,0,0,250,390.87a19.92,19.92,0,1,0-28.17-28.17,59.77,59.77,0,0,1-84.53-84.53ZM250,109.13a19.92,19.92,0,0,0,28.17,28.17,59.77,59.77,0,1,1,84.53,84.53l-42.27,42.26c-22.57,22.57-61.94,22.57-84.51,0a19.92,19.92,0,1,0-28.17,28.17,99.57,99.57,0,0,0,140.85,0L390.87,250A99.61,99.61,0,0,0,250,109.13Z" />
    </symbol>

    <symbol id="icon_linkedin" viewBox="0 0 500 500">
      <path
        d="M120.86,162A40.87,40.87,0,1,0,80,121.16,40.86,40.86,0,0,0,120.86,162Zm79.45,31V419.69H270.7V307.57c0-29.58,5.57-58.23,42.25-58.23,36.17,0,36.62,33.82,36.62,60.1V419.71H420V295.38c0-61.07-13.15-108-84.53-108-34.27,0-57.24,18.81-66.64,36.61h-.95V193ZM85.6,193h70.5V419.69H85.6Z" />
    </symbol>

    <symbol id="icon_load" viewBox="0 0 500 500">
      <path
        d="M250,318l68-85H267V114H233V233H182Zm136,34H114V233H80V352a34,34,0,0,0,34,34H386a34,34,0,0,0,34-34V233H386Z" />
    </symbol>

    <symbol id="icon_loader" viewBox="0 0 500 500">
      <path
        d="M250,420c92.16,0,170-77.84,170-170H386c0,73.73-62.27,136-136,136S114,323.73,114,250s62.27-136,136-136V80C157.84,80,80,157.86,80,250S157.84,420,250,420Z" />
    </symbol>

    <symbol id="icon_logout" viewBox="0 0 500 500">
      <path
        d="M301,267V233H199V182l-85,68,85,68V267Zm51-153V386H233v34H352a34,34,0,0,0,34-34V114a34,34,0,0,0-34-34H233v34Z" />
    </symbol>

    <symbol id="icon_remove" viewBox="0 0 500 500">
      <path
        d="M377.5,80,250,207.5,122.5,80,80,122.51,207.49,250,80,377.49,122.5,420,250,292.5,377.5,420,420,377.49,292.51,250,420,122.51Z" />
    </symbol>

    <symbol id="icon_reset" viewBox="0 0 500 500">
      <path
        d="M107.39,245.1a146.74,146.74,0,0,0,0,58.65,143.21,143.21,0,0,0,8.5,27.34,145,145,0,0,0,13.39,24.74,148.31,148.31,0,0,0,17.77,21.52,146.39,146.39,0,0,0,21.55,17.78,143.07,143.07,0,0,0,24.77,13.43A144.68,144.68,0,0,0,250,420a148,148,0,0,0,29.32-2.94,144.86,144.86,0,0,0,27.36-8.5,142.53,142.53,0,0,0,24.75-13.43,146.59,146.59,0,0,0,21.5-17.73,151,151,0,0,0,17.77-21.56,145.6,145.6,0,0,0-91.38-224c-4.06-.83-8.19-1.39-12.32-1.88V80l-85,68,85,68V164.3c1.84.29,3.69.46,5.51.84a111,111,0,0,1,20.91,6.49,112.57,112.57,0,0,1,18.94,10.28,111.34,111.34,0,0,1,49.19,92.52,110.83,110.83,0,0,1-19,62.35,113.55,113.55,0,0,1-13.65,16.55,111.76,111.76,0,0,1-56.39,30.39,114.27,114.27,0,0,1-45,0,112.16,112.16,0,0,1-20.89-6.51,109.17,109.17,0,0,1-18.94-10.27,112.07,112.07,0,0,1-30.19-30.17,108.2,108.2,0,0,1-10.25-18.91,110.34,110.34,0,0,1-6.53-20.92A111.44,111.44,0,0,1,147.2,231a109.26,109.26,0,0,1,10.27-18.95l-28.19-19a145.33,145.33,0,0,0-21.89,52.07Z" />
    </symbol>

    <symbol id="icon_save" viewBox="0 0 500 500">
      <path
        d="M117.78,420H382.22A37.79,37.79,0,0,0,420,382.22V174.44L325.56,80H117.78A37.79,37.79,0,0,0,80,117.78V382.22A37.79,37.79,0,0,0,117.78,420Zm37.78-302.22h75.55v37.78h37.78V117.78h37.78v75.55H155.56Zm0,151.11H344.44V382.22H155.56Z" />
    </symbol>

    <symbol id="icon_settings" viewBox="0 0 500 500">
      <path
        d="M414.7,228.68c-2.72-1.74-24.17-14.55-32.67-18.46l-10.55-25.5c3.14-8.43,9.15-32.06,10.2-36.18A11.37,11.37,0,0,0,378.61,138L362,121.48a11.26,11.26,0,0,0-10.54-3.06c-3.14.72-27.2,6.8-36.18,10.2l-25.5-10.54c-3.71-8.16-16.19-29.08-18.46-32.67A11.28,11.28,0,0,0,261.7,80H238.3a11.37,11.37,0,0,0-9.62,5.3c-1.74,2.7-14.55,24.17-18.46,32.7l-25.5,10.55c-8.43-3.14-32.06-9.15-36.18-10.2a11.37,11.37,0,0,0-10.54,3L121.48,138a11.26,11.26,0,0,0-3.06,10.54c.72,3.14,6.8,27.2,10.2,36.18l-10.54,25.5c-8.16,3.71-29.08,16.19-32.67,18.46A11.28,11.28,0,0,0,80,238.3v23.36a11.47,11.47,0,0,0,5.3,9.63C88,273,109.47,285.85,118,289.75l10.55,25.5c-3.14,8.43-9.15,32.06-10.2,36.18a11.37,11.37,0,0,0,3,10.57l16.53,16.52a11.29,11.29,0,0,0,10.53,3.07c3.13-.72,27.2-6.8,36.18-10.2l25.5,10.55c3.7,8.15,16.19,29.07,18.46,32.67a11.39,11.39,0,0,0,4.15,4A11.28,11.28,0,0,0,238.3,420h23.36a11.47,11.47,0,0,0,9.63-5.3c1.71-2.7,14.56-24.17,18.46-32.7l25.5-10.55c8.43,3.14,32.06,9.15,36.18,10.2a11.5,11.5,0,0,0,5.68-.15,11.38,11.38,0,0,0,4.89-2.88l16.52-16.53a11.29,11.29,0,0,0,3.07-10.53c-.72-3.13-6.8-27.2-10.2-36.18l10.55-25.5c8.15-3.7,29.07-16.19,32.67-18.46A11.31,11.31,0,0,0,420,261.7V238.3a11.22,11.22,0,0,0-5.3-9.62ZM250,324a74,74,0,1,1,28.35-5.63A74.2,74.2,0,0,1,250,324Z" />
    </symbol>

    <symbol id="icon_share" viewBox="0 0 500 500">
      <path
        d="M90 250C90 281.242 115.416 306.667 146.647 306.667C161.64 306.667 175.197 300.717 185.337 291.178L298.461 355.853C298.121 358.309 297.706 360.764 297.706 363.333C297.706 394.576 323.122 420 354.353 420C385.584 420 411 394.576 411 363.333C411 332.091 385.584 306.667 354.353 306.667C339.36 306.667 325.803 312.617 315.663 322.156L202.539 257.499C202.879 255.024 203.294 252.569 203.294 250C203.294 247.431 202.879 244.976 202.539 242.501L315.663 177.844C325.803 187.383 339.36 193.333 354.353 193.333C385.584 193.333 411 167.909 411 136.667C411 105.424 385.584 80 354.353 80C323.122 80 297.706 105.424 297.706 136.667C297.706 139.236 298.121 141.691 298.461 144.166L185.337 208.822C174.895 198.899 161.049 193.357 146.647 193.333C115.416 193.333 90 218.758 90 250Z" />
    </symbol>

    <symbol id="icon_vertical-dots" viewBox="0 0 500 500">
      <path
        d="M250,207.5A42.5,42.5,0,1,0,292.5,250,42.62,42.62,0,0,0,250,207.5ZM250,80a42.5,42.5,0,1,0,42.5,42.5A42.62,42.62,0,0,0,250,80Zm0,255a42.5,42.5,0,1,0,42.5,42.5A42.62,42.62,0,0,0,250,335Z" />
    </symbol>

    <symbol id="icon_warning-triangle" viewBox="0 0 500 500">
      <path
        d="M265,97.19c-5.88-11.12-24.17-11.12-30,0l-153,289a17,17,0,0,0,15,25H403a17,17,0,0,0,15-24.94Zm2,263H233v-34h34Zm-34-68v-85h34v85Z" />
    </symbol>

    <!-- Project specific icons -->
  </svg>
</template>

<style scoped lang="scss">
svg {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
</style>
