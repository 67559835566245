import { DEFAULT_SETTINGS } from '@/constants';
import { userService } from '@/services/user-service';
import { user } from './state';
import { CreateSscUserSettingDto } from '@/types/user-setting';

export const getSettings = async () => {
  const response = await userService.getSettings();

  switch (response.status) {
    case 200: {
      if (response.ok) {
        user.settings = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getSettings: Unhandled response', response);
      } else {
        console.error('getSettings: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const updateSettings = async (settings: CreateSscUserSettingDto) => {
  const response = await userService.putSettings(settings);

  switch (response.status) {
    case 200:
    case 201:
    case 204: {
      user.settings = settings;

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('updateSettings: Unhandled response', response);
      } else {
        console.error('updateSettings: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const resetSettings = () => {
  user.settings = DEFAULT_SETTINGS;
};
