/* eslint-disable no-console */

import { register } from 'register-service-worker';

const shouldLog = process.env.VUE_APP_LOGGING === 'true';
const isProduction = process.env.NODE_ENV === 'production';
/* eslint-disable no-console */

if (isProduction) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      if (shouldLog) {
        console.info(
          'App is being served from cache by a service worker. \n For more details, visit https://goo.gl/AFskqB',
        );
      }
    },
    registered() {
      if (shouldLog) {
        console.info('Service worker has been registered.');
      }
    },
    cached() {
      if (shouldLog) {
        console.info('Content has been cached for offline use.');
      }
    },
    updatefound() {
      if (shouldLog) {
        console.info('New content is downloading.');
      }
    },
    updated() {
      if (shouldLog) {
        console.info('New content is available; please refresh.');
      }

      console.info('Trying to refresh content automagically.');

      localStorage.removeItem('SSC_CALCULATION');

      window.location.reload();
    },
    offline() {
      if (shouldLog) {
        console.warn(
          'No internet connection found. App is running in offline mode.',
        );
      }
    },
    error(error) {
      if (shouldLog) {
        console.error('Error during service worker registration:', error);
      }
    },
  });
}
