import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { requireAuth, requireUnauth } from './authGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Authentication',
    component: () =>
      import(/* webpackChunkName: "authentication" */ '@/views/Auth.vue'),
    beforeEnter: requireUnauth,
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
      ),
    beforeEnter: requireUnauth,
  },
  {
    path: '/calculate',
    name: 'Calculate',
    component: () =>
      import(/* webpackChunkName: "calculate" */ '@/views/Calculate.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/result/:id',
    name: 'Result',
    component: () =>
      import(/* webpackChunkName: "result" */ '@/views/Result.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/FAQ.vue'),
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () =>
      import(/* webpackChunkName: "disclaimer" */ '@/views/Disclaimer.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue'
      ),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, __from, savedPosition) {
    if (to.name === 'Disclaimer' || to.name === 'FAQ') {
      return { top: 0 };
    }

    return savedPosition || { top: 0 };
  },
});
