import { AmountUnit, TemperatureUnit } from '@/types/enums';
import { SscUserSettingDto } from '@/types/user-setting';

export const VUE_APP_API_ENDPOINT =
  (window as CustomWindow)?.endpointAPI ??
  process.env.VUE_APP_API_ENDPOINT ??
  '';

export const STORAGE_PREFIX = 'SSC_';
export const ID_SEPARATOR = '__';

export const MAX_RANGE_VALUES = 1;
export const DEFAULT_TITRATABLE_ACIDITY_PH = 7;
export const MAXIMUM_STEPS = 25;
export const PROGRESS_POLL_RATE = 1000; // in ms

export const MIN_TEMPERATURE_IN_C = 80;
export const MAX_TEMPERATURE_IN_C = 145;

export const DEFAULT_SETTINGS: SscUserSettingDto = {
  defaults: {
    temperature: {
      value: 140,
      unit: TemperatureUnit.CELSIUS,
    },
    unit: AmountUnit.WEIGHT_PERCENTAGE,
  },
};
