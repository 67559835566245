<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="sb_icon-info"
    viewBox="0 0 500 500"
    v-bind="$attrs">
    <g>
      <path
        d="M250,95C164.53,95,95,164.53,95,250s69.53,155,155,155,155-69.53,155-155S335.47,95,250,95Zm15.5,232.5h-31v-93h31Zm0-124h-31v-31h31Z"
        class="sb_icon-info_circle" />
      <path
        d="M234.5,234.5h31v93h-31Zm0-62h31v31h-31Z"
        class="sb_icon-info_i" />
    </g>
  </svg>
</template>

<style scoped lang="scss">
.sb_icon-info {
  --icon-size: 24px;
  --color: #{$brand-secondary};
  --background-opacity: 0.1;

  width: var(--icon-size);
  height: var(--icon-size);

  &_circle {
    fill: var(--color);
    opacity: var(--background-opacity);
    transition-property: opacity;
    transition-timing-function: $transition-timing-function;
    transition-duration: $transition-duration-short;
  }

  &_i {
    fill: var(--color);
  }
}
</style>
