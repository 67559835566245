import { ref, reactive } from 'vue';

export interface ConfirmationDialog {
  title?: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
  onDismiss: () => void;
  onCancel: () => void;
  onOk: () => void;
}

const showConfirmationDialog = ref<boolean>(false);
const confirmationDialog = reactive<ConfirmationDialog | Record<string, never>>(
  {},
);
const showConsentModal = ref<boolean>(false);

export const misc = reactive({
  showConfirmationDialog,
  confirmationDialog,
  showConsentModal,
});
