import { ref, watch, reactive } from 'vue';
import { uuid } from '@/utils';
import {
  CalculationProgressDto,
  CreateSscFormulationDto,
  SscCalculationResultsDto,
  SscIngredient,
} from '@/types/calculation';
import { DeepPartial } from 'chart.js/types/utils';
import { TemperatureUnit } from '@/types/enums';
import { user } from '../user/state';

const LS_NAME = 'SSC_CALCULATION';

const calculating = ref<boolean>(false);
const hasChanged = ref<boolean>(false);
const id = ref<string | undefined>();
const progress = ref<CalculationProgressDto | undefined>();
const result = ref<SscCalculationResultsDto | undefined>();
const formulationOfResult = ref<CreateSscFormulationDto | undefined>();

export type UiIngredient = DeepPartial<SscIngredient> & {
  uid: string;
  amount: { value: number };
};

const scenarios = ref<Array<Array<UiIngredient>>>([
  [
    {
      uid: uuid(),
      ingredientId: undefined,
      ingredientKind: undefined,
      amount: {
        value: 0,
      },
    },
  ],
  [
    {
      uid: uuid(),
      ingredientId: undefined,
      ingredientKind: undefined,
      amount: {
        value: 0,
      },
    },
  ],
]);

const temperature = ref<{ unit: TemperatureUnit; value: number }>({
  value: user.settings.defaults.temperature.value,
  unit: user.settings.defaults.temperature.unit,
});

export const calculation = reactive({
  scenarios,
  temperature,
  result,
  calculating,
  formulationOfResult,
  hasChanged,
  id,
  progress,
});

const init = () => {
  const ls = localStorage.getItem(LS_NAME);

  if (!ls) {
    return;
  }

  const data = JSON.parse(ls);

  if (data.scenarios) calculation.scenarios = data.scenarios;
};

init();

watch(calculation, (current) => {
  localStorage.setItem(LS_NAME, JSON.stringify(current));
});
